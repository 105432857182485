import { Formik, Form, Field, ErrorMessage } from "formik";
import { makeRequest } from "../Axios/Axios.jsx";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Email inválido").required("O email é obrigatório"),
  senha: Yup.string()
    .required("Senha é obrigatória")
    .matches(
      /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
      "A senha deve conter pelo menos 8 caracteres, incluindo um caractere especial."
    ),
  repeatSenha: Yup.string()
    .required("Repita a senha")
    .oneOf([Yup.ref("senha"), null], "As senhas devem coincidir"),
});

export const CrieSuaConta = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const toastId = toast.loading("Carregando...");

    await makeRequest
      .post(
        "/users/create",
        { email: values.email, senha: values.senha },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data.message);
        localStorage.setItem("email", values.email);
        toast.update(toastId, {
          render: `${response.data.message}`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        navigate("/confirme-email");
      })
      .catch((error) => {
        toast.update(toastId, {
          render: (
            <Link to="/reconfirme-email"> {error.response.data.message}</Link>
          ),
          type: "error",
          isLoading: false,
          autoClose: 10000,
          theme: "dark",
        });
        console.log(error.response.data.message);
      });
  };

  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="pb-12 text-center">
            <h1 className="animate-[gradient_6s_linear_infinite] bg-[linear-gradient(to_right,theme(colors.gray.200),theme(colors.indigo.200),theme(colors.gray.50),theme(colors.indigo.300),theme(colors.gray.200))] bg-[length:200%_auto] bg-clip-text font-nacelle text-3xl font-semibold text-transparent md:text-4xl">
              Crie uma conta
            </h1>
          </div>
          {/* Formik Form */}
          <Formik
            initialValues={{
              email: "",
              senha: "",
              repeatSenha: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="mx-auto max-w-[400px]">
                <div className="space-y-5">
                  {/* <div>
                    <label
                      className="mb-1 block text-sm font-medium text-indigo-200/65"
                      htmlFor="name"
                    >
                      Name <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      className="form-input w-full"
                      placeholder="Your full name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div> */}
                  {/* <div>
                    <label
                      className="mb-1 block text-sm font-medium text-indigo-200/65"
                      htmlFor="company"
                    >
                      Company Name <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="company"
                      name="company"
                      type="text"
                      className="form-input w-full"
                      placeholder="Your company name"
                    />
                    <ErrorMessage
                      name="company"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div> */}
                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-indigo-200/65"
                      htmlFor="email"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className="form-input w-full"
                      placeholder="Seu email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-indigo-200/65"
                      htmlFor="senha"
                    >
                      Senha <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="senha"
                      name="senha"
                      type="password"
                      className="form-input w-full"
                      placeholder="Senha (mais de 8 caracteres)"
                    />
                    <ErrorMessage
                      name="senha"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-indigo-200/65"
                      htmlFor="repeatSenha"
                    >
                      Repita a senha <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="repeatSenha"
                      name="repeatSenha"
                      type="password"
                      className="form-input w-full"
                      placeholder="Repita sua senha"
                    />
                    <ErrorMessage
                      name="repeatSenha"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>
                </div>
                <div className="mt-6 space-y-5">
                  <button
                    type="submit"
                    className="btn w-full bg-gradient-to-t from-indigo-600 to-indigo-500 bg-[length:100%_100%] bg-[bottom] text-white shadow-[inset_0px_1px_0px_0px_theme(colors.white/.16)] hover:bg-[length:100%_150%]"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Cadastrando..." : "Cadastrar"}
                  </button>
                  <div className="flex items-center gap-3 text-center text-sm italic text-gray-600 before:h-px before:flex-1 before:bg-gradient-to-r before:from-transparent before:via-gray-400/25 after:h-px after:flex-1 after:bg-gradient-to-r after:from-transparent after:via-gray-400/25">
                    ou
                  </div>
                  <button
                    type="button"
                    className="btn relative w-full bg-gradient-to-b from-gray-800 to-gray-800/60 bg-[length:100%_100%] bg-[bottom] text-gray-300 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_right,theme(colors.gray.800),theme(colors.gray.700),theme(colors.gray.800))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)] hover:bg-[length:100%_150%]"
                  >
                    Entrar com Google
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {/* Bottom link */}
          <div className="mt-6 text-center text-sm text-indigo-200/65">
            Você já tem uma conta?{" "}
            <Link className="font-medium text-indigo-500" to="/entrar">
              Entrar
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
