import { useEffect, useState } from "react";
import { Header } from "../../components/Header/Header";
import { Menu } from "../../components/Menu/Menu";
import { Metrics } from "../../components/Metrics/Metrics";
import { CandleChart } from "../../components/CandleChart/CandleChart";
import { SelectOptions } from "../../components/SelectOptions/SelectOptions";
import { makeRequest } from "../../components/Axios/Axios";
import { useNavigate } from "react-router-dom";

export const Dashboard = () => {
  const [ativo, setAtivo] = useState("BTCUSDT");
  const [dataInicio, setDataInicio] = useState(
    new Date().toISOString().split("T")[0]
  );
  const storedTheme = localStorage.getItem("theme") || "dark";
  const [theme, setTheme] = useState(storedTheme);

  const [options, setOptions] = useState({ series: [] });
  const [metricValues, setMetricValues] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const verifyToken = async () => {
      try {
        await makeRequest.get("users/verify/token");
      } catch (err) {
        navigate("/");
      }
    };

    verifyToken();
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await makeRequest
          .post(
            `/chart/get-configs`,
            { ativo: ativo, dataInicio: dataInicio, theme: theme },
            { withCredentials: true }
          )
          .then((response) => {
            const { options, metricValues } = response.data.data;

            setOptions(options);
            setMetricValues(metricValues);
          })
          .catch((error) => {
            console.log(error.response);
            navigate("/");
          });
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, [ativo, dataInicio, theme, navigate]);

  return (
    <>
      <Menu />
      {/*<div className="2xl:h-screen flex flex-col">*/}
      <div className="h-screen flex flex-col">
        <Header theme={theme} setTheme={setTheme} />
        <SelectOptions
          setDataInicio={setDataInicio}
          dataInicio={dataInicio}
          setAtivo={setAtivo}
          ativo={ativo}
        />
        {/* <div className="flex flex-col md:flex-row flex-grow justify-between overflow-y-auto"> */}
        <div className="flex flex-col md:flex-row flex-grow justify-between">
          <CandleChart options={options} />
          <Metrics ativo={ativo} metricValues={metricValues} />
        </div>
      </div>
    </>
  );
};
