import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Auth } from "../page/Auth/Auth";
import { Dashboard } from "../page/Main/Dashboard";
import { Home } from "../page/Main/Home";
import { PaymentPage } from "../page/Payments/Payments";

export const Routers = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/entrar" element={<Auth />} />
        <Route path="/cadastrar" element={<Auth />} />
        <Route path="/confirme-email" element={<Auth />} />
        <Route path="/reconfirme-email" element={<Auth />} />
        <Route path="/resetar-senha" element={<Auth />} />
        <Route path="/atualizar-senha" element={<Auth />} />
        <Route path="/verifique-email" element={<Auth />} />

        <Route path="/trading" element={<Dashboard />} />
        <Route path="/payment-page" element={<PaymentPage />} />
        <Route path="/contact" element={<></>} />
      </Routes>
    </Router>
  );
};
