import { useEffect, useState, useRef } from "react";

const useMasonry = () => {
  const masonryContainer = useRef(null); // Referência para o contêiner de itens de masonry
  const [items, setItems] = useState([]); // Estado para armazenar os itens filhos do contêiner

  useEffect(() => {
    // Esse efeito roda quando o componente monta
    if (masonryContainer.current) {
      const masonryItem = Array.from(masonryContainer.current.children);
      setItems(masonryItem); // Define os itens filhos para o estado
    }
  }, []);

  useEffect(() => {
    // Esse efeito roda toda vez que o estado "items" muda
    const handleMasonry = () => {
      if (!items || items.length < 1) return;
      let gapSize = 0;
      if (masonryContainer.current) {
        // Obtém o tamanho do gap entre as colunas do grid
        gapSize = parseInt(
          window
            .getComputedStyle(masonryContainer.current)
            .getPropertyValue("grid-row-gap")
        );
      }

      items.forEach((el) => {
        if (!(el instanceof HTMLElement)) return;
        let previous = el.previousSibling;
        // Verifica a posição de cada item e ajusta a margem superior para simular o efeito masonry
        while (previous) {
          if (previous.nodeType === 1) {
            el.style.marginTop = "0";
            if (
              previous instanceof HTMLElement &&
              elementLeft(previous) === elementLeft(el)
            ) {
              el.style.marginTop =
                -(elementTop(el) - elementBottom(previous) - gapSize) + "px";
              break;
            }
          }
          previous = previous.previousSibling;
        }
      });
    };

    handleMasonry(); // Chama a função que aplica o estilo masonry
    window.addEventListener("resize", handleMasonry); // Adiciona um ouvinte de evento para redimensionamento
    return () => {
      window.removeEventListener("resize", handleMasonry); // Limpeza do evento ao desmontar o componente
    };
  }, [items]);

  // Funções auxiliares para calcular as posições dos elementos
  const elementLeft = (el) => {
    return el.getBoundingClientRect().left;
  };

  const elementTop = (el) => {
    return el.getBoundingClientRect().top + window.scrollY;
  };

  const elementBottom = (el) => {
    return el.getBoundingClientRect().bottom + window.scrollY;
  };

  return masonryContainer;
};

export default useMasonry;
