import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { makeRequest } from "../Axios/Axios.jsx";
import * as Yup from "yup";
import { toast } from "react-toastify";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Email inválido").required("O email é obrigatório"),
  senha: Yup.string()
    .required("Senha é obrigatória")
    .matches(
      /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
      "A senha deve conter pelo menos 8 caracteres, incluindo um caractere especial."
    ),
});

export const LoginSuaConta = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const toastId = toast.loading("Carregando...");

    await makeRequest
      .post(
        "/users/login",
        {
          email: values.email,
          senha: values.senha,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data.message);
        toast.update(toastId, {
          render: `${response.data.message}`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        navigate("/trading");
      })
      .catch((error) => {
        toast.update(toastId, {
          render: (
            <Link to="/reconfirme-email">
              {error.response.data.message} Clicando aqui.
            </Link>
          ),
          type: "error",
          isLoading: false,
          autoClose: 10000,
          theme: "dark",
        });
        console.log(error.response.data.message);
      });
  };
  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="pb-12 text-center">
            <h1 className="pb-1 animate-[gradient_6s_linear_infinite] bg-[linear-gradient(to_right,theme(colors.gray.200),theme(colors.indigo.200),theme(colors.gray.50),theme(colors.indigo.300),theme(colors.gray.200))] bg-[length:200%_auto] bg-clip-text font-nacelle text-3xl font-semibold text-transparent md:text-4xl">
              Seja bem vindo
            </h1>
          </div>
          {/* Formik Form */}
          <Formik
            initialValues={{ email: "", senha: "" }}
            validationSchema={SignupSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="mx-auto max-w-[400px]">
                <div className="space-y-5">
                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-indigo-200/65"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className="form-input w-full"
                      placeholder="Seu email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <div className="mb-1 flex items-center justify-between gap-3">
                      <label
                        className="block text-sm font-medium text-indigo-200/65"
                        htmlFor="senha"
                      >
                        Senha
                      </label>
                      <Link
                        className="text-sm text-gray-600 hover:underline"
                        to="/resetar-senha"
                      >
                        Esqueceu?
                      </Link>
                    </div>
                    <Field
                      id="senha"
                      name="senha"
                      type="password"
                      className="form-input w-full"
                      placeholder="Sua senha"
                    />
                    <ErrorMessage
                      name="senha"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>
                </div>
                <div className="mt-6 space-y-5">
                  <button
                    type="submit"
                    className="btn w-full bg-gradient-to-t from-indigo-600 to-indigo-500 bg-[length:100%_100%] bg-[bottom] text-white shadow-[inset_0px_1px_0px_0px_theme(colors.white/.16)] hover:bg-[length:100%_150%]"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Entrando..." : "Entrar"}
                  </button>
                  <div className="flex items-center gap-3 text-center text-sm italic text-gray-600 before:h-px before:flex-1 before:bg-gradient-to-r before:from-transparent before:via-gray-400/25 after:h-px after:flex-1 after:bg-gradient-to-r after:from-transparent after:via-gray-400/25">
                    ou
                  </div>
                  <button
                    type="button"
                    className="btn relative w-full bg-gradient-to-b from-gray-800 to-gray-800/60 bg-[length:100%_100%] bg-[bottom] text-gray-300 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_right,theme(colors.gray.800),theme(colors.gray.700),theme(colors.gray.800))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)] hover:bg-[length:100%_150%]"
                  >
                    Entrar com Google
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {/* Bottom link */}
          <div className="mt-6 text-center text-sm text-indigo-200/65">
            Ainda não tem uma conta?{" "}
            <Link className="font-medium text-indigo-500" to="/cadastrar">
              Cadastre-se
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
