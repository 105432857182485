import { Formik, Form, Field, ErrorMessage } from "formik";
import { makeRequest } from "../Axios/Axios.jsx";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";

const SignupSchema = Yup.object().shape({
  numero: Yup.number().required("O número é obrigatório"),
});

export const ConfirmeSuaConta = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    console.log(values);
    const toastId = toast.loading("Carregando...");

    await makeRequest
      .post(
        "/users/confirm",
        {
          email: localStorage.getItem("email"),
          numero: parseInt(values.numero),
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data.message);
        toast.update(toastId, {
          render: `${response.data.message}`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        navigate("/");
      })
      .catch((error) => {
        toast.update(toastId, {
          render: `${error.response.data.message}`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        console.log(error.response.data.message);
      });
  };

  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="pb-12 text-center">
            <h1 className="animate-[gradient_6s_linear_infinite] bg-[linear-gradient(to_right,theme(colors.gray.200),theme(colors.indigo.200),theme(colors.gray.50),theme(colors.indigo.300),theme(colors.gray.200))] bg-[length:200%_auto] bg-clip-text font-nacelle text-3xl font-semibold text-transparent md:text-4xl">
              Confirme seu email
            </h1>
          </div>
          <Formik
            initialValues={{
              numero: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={async (values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
            }}
          >
            {({ isSubmitting }) => (
              <Form className="mx-auto max-w-[400px]">
                <div>
                  <label
                    className="mb-1 block text-sm font-medium text-indigo-200/65"
                    htmlFor="numero"
                  >
                    Número
                  </label>
                  <Field
                    id="numero"
                    name="numero"
                    type="string"
                    className="form-input w-full"
                    placeholder="Número"
                  />
                  <ErrorMessage
                    name="numero"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div className="mt-6">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn w-full bg-gradient-to-t from-indigo-600 to-indigo-500 bg-[length:100%_100%] bg-[bottom] text-white shadow-[inset_0px_1px_0px_0px_theme(colors.white/.16)] hover:bg-[length:100%_150%]"
                  >
                    {isSubmitting ? "Enviando..." : "Enviar"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};
