import { useEffect, useState } from "react";
import Modal from "react-modal";
import { makeRequest } from "../Axios/Axios";
// import { useNavigate } from "react-router-dom";

// Configuração de acessibilidade do modal
Modal.setAppElement("#root");

export const Modals = ({ togglePopup, popupVisible }) => {
  //   const [popupVisible, setPopupVisible] = useState(false); // Controle do popup

  //   const togglePopup = () => setPopupVisible(!popupVisible);

  const [data, setData] = useState({});
  const accountInfos = () => {
    makeRequest
      .get("/users/get/data", { withCredentials: true })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // console.log(error.response.data.message);
        console.log(error.response);
      });
  };

  useEffect(() => {
    accountInfos();
  }, []);

  const date = new Date(data.data);

  // const navigate = useNavigate();
  // const logout = () => {
  //   makeRequest
  //     .get("/users/logout", { withCredentials: true })
  //     .then((response) => {
  //       console.log(response.data);
  //       navigate("/");
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data.message);
  //     });
  // };

  return (
    <Modal
      isOpen={popupVisible}
      onRequestClose={togglePopup}
      contentLabel="Conta Modal"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      className="bg-white dark:modal-bg-less rounded-lg p-5 w-80 shadow-lg text-center outline-none"
    >
      <h2 className="text-xl font-bold mb-4 dark:text-white text-gray-600">
        Detalhes da Conta
      </h2>
      <p className="dark:text-white text-gray-600">
        Aqui estão as informações da sua conta
      </p>

      <div className="flex flex-col dark:text-white text-gray-600">
        <span>{data.email}</span>
        <span>
          {`Criada em: ${String(date.getDate()).padStart(2, "0")}/${String(
            date.getMonth() + 1
          ).padStart(2, "0")}/${date.getFullYear()}`}
        </span>
      </div>

      <div className="flex gap-5">
        <button
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
          onClick={togglePopup}
        >
          Fechar
        </button>
        {/* <button
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
          onClick={logout}
        >
          Logout
        </button> */}
      </div>
      <button
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
        onClick={togglePopup}
      >
        Alterar dados
      </button>
    </Modal>
  );
};
