import { useState } from "react";
import Modal from "react-modal";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { TbLogout2 } from "react-icons/tb";
import { makeRequest } from "../Axios/Axios";
import { useNavigate } from "react-router-dom";
import { FaRegCreditCard } from "react-icons/fa";
import { CreditCardForm } from "../Payments/PaymentsModal";

Modal.setAppElement("#root");

export const Menu = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const modifyState = () => setState(!state);

  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => setPopupVisible(!popupVisible);

  const logout = () => {
    makeRequest
      .get("/users/logout", { withCredentials: true })
      .then((response) => {
        console.log(response.data);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const [popupVisibles, setPopupVisibles] = useState(false);
  const togglePopups = () => setPopupVisibles(!popupVisibles);

  return (
    <>
      <div
        className={`h-full fixed bg-[#141518] w-64 z-10 flex items-center justify-end ${
          state ? "left-0" : "left-[-256px]"
        } transition-all duration-300 ease`}
      >
        <div
          className="absolute z-20 right-[-35px] cursor-pointer dark:text-white text-neutral-800"
          onClick={modifyState}
        >
          {state ? (
            <IoIosArrowBack size={42} />
          ) : (
            <IoIosArrowForward size={42} />
          )}
        </div>
        <div className="absolute top-0 w-full flex p-3 text-white gap-2 cursor-pointer">
          <div>
            <div className="flex gap-2 items-center" onClick={togglePopups}>
              {/* <TbLogout2  /> */}
              <FaRegCreditCard size={26} />
              Pagamentos
            </div>
            {/* <div className="flex gap-2 items-center">
              <TbLogout2 size={26} />
              Saira
            </div> */}
          </div>
        </div>
        <div
          className="absolute bottom-0 w-full flex p-3 text-white gap-2 cursor-pointer"
          onClick={togglePopup}
        >
          <div className="flex gap-2 items-center">
            <TbLogout2 size={26} />
            Sair
          </div>
        </div>
      </div>

      <Modal
        isOpen={popupVisible}
        onRequestClose={togglePopup}
        contentLabel="Conta Modal"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        className="bg-white dark:modal-bg-less rounded-lg p-5 w-80 shadow-lg text-center outline-none"
      >
        <h2 className="text-xl font-bold mb-4 dark:text-white text-gray-600">
          Deseja realmente sair?
        </h2>

        <div className="flex gap-5">
          <button
            className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 w-full"
            onClick={logout}
          >
            Sair
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={popupVisibles}
        onRequestClose={togglePopups}
        contentLabel="Conta Modal"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        className="bg-white dark:modal-bg-less rounded-lg p-5 w-96 shadow-lg text-center outline-none"
      >
        <h2 className="text-xl font-bold mb-4 dark:text-white text-gray-600">
          Atualizar informações de pagamento
        </h2>

        <div>
          <CreditCardForm />
        </div>
      </Modal>
    </>
  );
};
