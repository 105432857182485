import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Formik, Field, Form } from "formik";
import { makeRequest } from "../Axios/Axios";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

export const PaymentForm = ({ priceId }) => {
  const [isLoged, setIsLoged] = useState(false);
  const validate = (values) => {
    const errors = {};

    if (!isLoged) {
      if (!values.email) {
        errors.email = "Obrigatório";
      }

      if (!values.password) {
        errors.password = "Obrigatório";
      } else if (values.password.length < 8) {
        errors.password = "Insira pelo menos 8 caracteres";
      } else if (!/[0-9]/.test(values.password)) {
        errors.password = "A senha deve conter pelo menos um número";
      } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(values.password)) {
        errors.password = "Adicione um caractere especial";
      }
    }

    if (!values.name) {
      errors.name = "Obrigatório";
    }

    if (!values.address) {
      errors.address = "Obrigatório";
    } else if (!/^\d{8}$/.test(values.address)) {
      errors.address = "Inválido";
    }

    return errors;
  };
  const [cardNumber, setCardNumber] = React.useState(null);
  // const [cardExpiry, setCardExpiry] = React.useState(null);
  // const [cardCvc, setCardCvc] = React.useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripe = await stripePromise;
      const elements = stripe.elements({
        fonts: [
          { cssSrc: "https://fonts.googleapis.com/css?family=Quicksand" },
        ],
        locale: "auto",
      });

      const elementStyles = {
        base: {
          color: "#777777",
          fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
          fontSize: "14px",
          fontSmoothing: "antialiased",
          ":focus": { color: "#252525" },
          "::placeholder": { color: "#777777" },
          ":focus::placeholder": { color: "#777777" },
        },
        invalid: {
          color: "#cf2626",
          ":focus": { color: "#777777" },
          "::placeholder": { color: "#cf2626" },
        },
      };

      const elementClasses = {
        focus: "focus",
        empty: "empty",
        invalid: "invalid",
      };

      const cardNumberElement = elements.create("cardNumber", {
        iconStyle: "solid",
        style: elementStyles,
        classes: elementClasses,
      });
      const cardExpiryElement = elements.create("cardExpiry", {
        style: elementStyles,
        classes: elementClasses,
      });
      const cardCvcElement = elements.create("cardCvc", {
        style: elementStyles,
        classes: elementClasses,
      });

      cardNumberElement.mount("#example3-card-number");
      cardExpiryElement.mount("#example3-card-expiry");
      cardCvcElement.mount("#example3-card-cvc");

      setCardNumber(cardNumberElement);
      // setCardExpiry(cardExpiryElement);
      // setCardCvc(cardCvcElement);
    };

    initializeStripe();
  }, []);

  const [dinamicState, setDinamicState] = useState("criar conta");
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    const stripe = await stripePromise;

    switch (dinamicState) {
      case "criar conta":
        setDinamicState("criando conta...");
        setSubmitting(true);
        await makeRequest
          .post(
            "/users/create",
            { email: values.email, senha: values.password },
            { withCredentials: true }
          )
          .then((response) => {
            console.log(response.data.message);
            localStorage.setItem("email", values.email);
            setDinamicState("confirmar");
            setSubmitting(false);
          })
          .catch((error) => {
            console.log(error.response.data.message);
            setDinamicState("Algo deu errado. Volte mais tarde");

            setTimeout(() => {
              setDinamicState("criar conta");
            }, 5000);
            setSubmitting(false);
          });
        break;
      case "confirmar":
      case "Tempo expirado. Cheque seu email":
        setDinamicState("confirmando código...");
        setSubmitting(true);
        await makeRequest
          .post(
            "/users/confirm",
            {
              email: localStorage.getItem("email"),
              numero: parseInt(values.codigo),
            },
            { withCredentials: true }
          )
          .then((response) => {
            console.log(response.data.message);

            setDinamicState("assinar");
            setSubmitting(false);
          })
          .catch((error) => {
            console.log(error.response.data.message);
            if (error.response.data.type === "time") {
              setDinamicState("Tempo expirado. Cheque seu email");
            } else {
              setDinamicState("Algo deu errado. Volte mais tarde");
              setSubmitting(false);
            }
          });
        break;
      case "assinar":
        setDinamicState("assinando...");
        setSubmitting(true);
        if (isLoged) {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardNumber, // O elemento do cartão é passado aqui
            billing_details: {
              name: values.name,
              email: email, // Você pode adicionar o e-mail ou outros dados aqui
              address: {
                postal_code: values.address, // Use o endereço de cobrança aqui
              },
            },
          });
          console.log(error);
          try {
            await makeRequest.post(
              "/payments/create-payment-method",
              { paymentMethod: paymentMethod, priceId: priceId },
              {
                withCredentials: true,
              }
            );
            setSubmitting(false);
            navigate("/trading");
          } catch (err) {
            console.log(err);
            setSubmitting(false);
            setDinamicState("Algo deu errado. Volte mais tarde");
          }
        } else {
          await makeRequest
            .post(
              "/users/login",
              {
                email: values.email,
                senha: values.password,
              },
              { withCredentials: true }
            )
            .then(async (response) => {
              console.log(response.data.message);

              const { error, paymentMethod } = await stripe.createPaymentMethod(
                {
                  type: "card",
                  card: cardNumber, // O elemento do cartão é passado aqui
                  billing_details: {
                    name: values.name,
                    email: values.email, // Você pode adicionar o e-mail ou outros dados aqui
                    address: {
                      postal_code: values.address, // Use o endereço de cobrança aqui
                    },
                  },
                }
              );
              console.log(error);
              try {
                await makeRequest.post(
                  "/payments/create-payment-method",
                  { paymentMethod: paymentMethod, priceId: priceId },
                  {
                    withCredentials: true,
                  }
                );
                setSubmitting(false);
                navigate("/trading");
              } catch (err) {
                console.log(err);
                setSubmitting(false);
                setDinamicState("Algo deu errado. Volte mais tarde");
              }
            })
            .catch((error) => {
              console.log(error.response.data.message);
              setDinamicState("Algo deu errado. Volte mais tarde");
              setSubmitting(false);
            });
        }
        break;
      default:
        break;
    }
  };

  const [email, setEmail] = useState("");

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const res = await makeRequest.get("users/get/data");

        setIsLoged(true);
        console.log(res.data.email);
        setEmail(res.data.email);
        setDinamicState("assinar");
      } catch (err) {
        console.log(err);
        setIsLoged(false);
      }
    };
    verifyToken();
  }, [navigate]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        name: "",
        address: "",
        paymentToken: "",
        codigo: "",
      }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, touched, errors, setFieldValue }) => (
        <Form
          className="example3 max-w-lg mx-auto p-5 pl-14 pr-14"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="flex flex-wrap gap-1">
            {isLoged ? (
              <>
                <div key="emailSenha" className="w-full">
                  <div className="flex justify-between w-full">
                    <label className="w-full text-sm font-semibold text-gray-500">
                      E-mail
                    </label>
                    {/* {touched.email && errors.email && (
                      <div className="text-red-600 text-sm mt-1 w-full text-end">
                        {errors.email}
                      </div>
                    )} */}
                  </div>

                  <Field
                    id="example3-email"
                    className={`w-full p-2 text-sm rounded-lg border mb-2 ${
                      touched.email && errors.email ? "" : "border-[#a0a0a049]"
                    } focus:ring focus:ring-offset-indigo-500 outline-none shadow-[0px_3px_5px_0px_rgba(0,0,0,0.1)] hover:shadow- transition-shadow`}
                    type="email"
                    name="email"
                    value={email}
                    readOnly
                  />
                </div>
              </>
            ) : (
              <>
                {dinamicState === "confirmar" ||
                dinamicState === "Tempo expirado. Cheque seu email" ||
                dinamicState === "confirmando código..." ? (
                  <>
                    <div key="codigo" className="w-full">
                      <div className="flex justify-between w-full">
                        <label className="w-full text-sm font-semibold text-gray-500">
                          Código
                        </label>
                        {touched.email && errors.email && (
                          <div className="text-red-600 text-sm mt-1 w-full text-end">
                            {errors.email}
                          </div>
                        )}
                      </div>

                      <Field
                        id="codigo"
                        className={`w-full p-2 text-sm rounded-lg border mb-2 ${
                          touched.email && errors.email
                            ? "border-red-600"
                            : "border-[#a0a0a049]"
                        } focus:ring focus:ring-offset-indigo-500 outline-none shadow-[0px_3px_5px_0px_rgba(0,0,0,0.1)] hover:shadow- transition-shadow`}
                        type="text"
                        name="codigo"
                        required
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div key="emailSenha" className="w-full">
                      <div className="flex justify-between w-full">
                        <label className="w-full text-sm font-semibold text-gray-500">
                          E-mail
                        </label>
                        {touched.email && errors.email && (
                          <div className="text-red-600 text-sm mt-1 w-full text-end">
                            {errors.email}
                          </div>
                        )}
                      </div>

                      <Field
                        id="example3-email"
                        className={`w-full p-2 text-sm rounded-lg border mb-2 ${
                          touched.email && errors.email
                            ? "border-red-600"
                            : "border-[#a0a0a049]"
                        } focus:ring focus:ring-offset-indigo-500 outline-none shadow-[0px_3px_5px_0px_rgba(0,0,0,0.1)] hover:shadow- transition-shadow`}
                        type="email"
                        name="email"
                        required
                      />

                      <div className="flex justify-between w-full">
                        <label className="w-full text-sm font-semibold text-gray-500">
                          Senha
                        </label>
                        {touched.password && errors.password && (
                          <div className="text-red-600 text-sm mt-1 w-full text-end">
                            {errors.password}
                          </div>
                        )}
                      </div>

                      <Field
                        id="example3-senha"
                        className={`w-full p-2 text-sm rounded-lg border ${
                          touched.password && errors.password
                            ? "border-red-600"
                            : "border-[#a0a0a049]"
                        } focus:ring focus:ring-offset-indigo-500 outline-none shadow-[0px_3px_5px_0px_rgba(0,0,0,0.1)] hover:shadow- transition-shadow`}
                        type="password"
                        name="password"
                        required
                      />
                    </div>
                  </>
                )}
              </>
            )}

            <label className="w-full text-md font-semibold text-gray-800 mt-8">
              Forma de pagamento
            </label>
            <label className="w-full text-sm font-semibold text-gray-500 mt-4">
              Dados do cartão
            </label>
            <div className="gap-0 w-full">
              <div
                id="example3-card-number"
                className="w-full p-2.5 rounded-t-lg border border-[#a0a0a049] border-b-0 focus:ring focus:ring-offset-indigo-500 outline-none hover:shadow transition-shadow"
              ></div>

              <div className="flex w-full">
                <div
                  id="example3-card-expiry"
                  className="w-full p-2.5 rounded-bl-lg border border-[#a0a0a049]"
                ></div>

                <div
                  id="example3-card-cvc"
                  className="w-full p-2.5 rounded-br-lg border border-[#a0a0a049]"
                ></div>
              </div>
            </div>
            <div className="flex justify-between w-full mt-4">
              <label className="w-full text-sm font-semibold text-gray-500">
                Nome do titular do cartão
              </label>
              {touched.name && errors.name && (
                <div className="text-red-600 text-sm mt-1">{errors.name}</div>
              )}
            </div>
            <Field
              id="example3-name"
              className={`w-full p-2 text-sm rounded-lg border ${
                touched.name && errors.name
                  ? "border-red-600"
                  : "border-[#a0a0a049]"
              } border-gray-300 focus:ring focus:ring-offset-indigo-500 outline-none shadow-[0px_3px_5px_0px_rgba(0,0,0,0.1)] hover:shadow- transition-shadow`}
              type="text"
              name="name"
              placeholder="Nome completo"
              required
            />
            <div className="flex justify-between w-full mt-4">
              <label className="w-full text-sm font-semibold text-gray-500">
                Endereço de cobrança
              </label>
              {touched.address && errors.address && (
                <div className="text-red-600 text-sm mt-1">
                  {errors.address}
                </div>
              )}
            </div>
            <Field
              id="example3-address"
              className={`w-full p-2 text-sm rounded-lg border ${
                touched.address && errors.address
                  ? "border-red-600"
                  : "border-[#a0a0a049]"
              } border-gray-300 focus:ring focus:ring-offset-indigo-500 outline-none shadow-[0px_3px_5px_0px_rgba(0,0,0,0.1)] hover:shadow- transition-shadow`}
              type="text"
              name="address"
              placeholder="Informe um endereço (CEP)"
              maxLength="8"
              pattern="\d{1,8}"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\D/g, ""); // Remove qualquer caractere que não seja número
              }}
              required
            />
          </div>

          <button
            type="submit"
            onClick={() => console.log(errors)}
            disabled={isSubmitting}
            className="mt-6 w-full py-3 bg-sky-600 text-gray-300 font-semibold rounded-lg uppercase focus:ring focus:ring-offset-indigo-500"
          >
            {dinamicState}
          </button>
        </Form>
      )}
    </Formik>
  );
};
