import {
  useNavigate,
  // useParams,
  Link,
  useLocation,
} from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { makeRequest } from "../Axios/Axios";
import * as Yup from "yup";
import { toast } from "react-toastify";

const SignupSchema = Yup.object().shape({
  senha: Yup.string()
    .required("Senha é obrigatória")
    .matches(
      /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
      "A senha deve conter pelo menos 8 caracteres, incluindo um caractere especial."
    ),
  repeatSenha: Yup.string()
    .required("Repita a senha")
    .oneOf([Yup.ref("senha"), null], "As senhas devem coincidir"),
});

export const AtualizaSenha = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hash = queryParams.get("hash");

  // const { hash } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const toastId = toast.loading("Carregando...");

    await makeRequest
      .put(
        `/users/update/password/${hash}`,
        { email: values.email, senha: values.senha },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data.message);
        localStorage.setItem("email", values.email);
        toast.update(toastId, {
          render: `${response.data.message}`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        navigate("/");
      })
      .catch((error) => {
        toast.update(toastId, {
          render: `Erro: ${error.response.data.message}`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        console.log(error.response.data.message);
      });
  };

  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="pb-12 text-center">
            <h1 className="pb-1 animate-[gradient_6s_linear_infinite] bg-[linear-gradient(to_right,theme(colors.gray.200),theme(colors.indigo.200),theme(colors.gray.50),theme(colors.indigo.300),theme(colors.gray.200))] bg-[length:200%_auto] bg-clip-text font-nacelle text-3xl font-semibold text-transparent md:text-4xl">
              Atualizar senha
            </h1>
          </div>
          {/* Formik Form */}
          <Formik
            initialValues={{
              senha: "",
              repeatSenha: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={async (values, { resetForm }) => {
              console.log("Submissão do formulário", values);
              handleSubmit(values);
              resetForm();
            }}
          >
            {({ isSubmitting }) => (
              <Form className="mx-auto max-w-[400px]">
                <div className="space-y-5">
                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-indigo-200/65"
                      htmlFor="senha"
                    >
                      Senha
                    </label>
                    <Field
                      id="senha"
                      name="senha"
                      type="password"
                      className="form-input w-full"
                      placeholder="Sua senha"
                    />
                    <ErrorMessage
                      name="senha"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <div className="mb-1 flex items-center justify-between gap-3">
                      <label
                        className="block text-sm font-medium text-indigo-200/65"
                        htmlFor="senha"
                      >
                        Repita a senha
                      </label>
                    </div>
                    <Field
                      id="repeatSenha"
                      name="repeatSenha"
                      type="password"
                      className="form-input w-full"
                      placeholder="Repita sua senha"
                    />
                    <ErrorMessage
                      name="repeatSenha"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>
                </div>
                <div className="mt-6 space-y-5">
                  <button
                    type="submit"
                    className="btn w-full bg-gradient-to-t from-indigo-600 to-indigo-500 bg-[length:100%_100%] bg-[bottom] text-white shadow-[inset_0px_1px_0px_0px_theme(colors.white/.16)] hover:bg-[length:100%_150%]"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Atualizando..." : "Atualizar"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {/* Bottom link */}
          <div className="mt-6 text-center text-sm text-indigo-200/65">
            Ainda não tem uma conta?{" "}
            <Link className="font-medium text-indigo-500" to="/cadastrar">
              Cadastre-se
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
