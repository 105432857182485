import React, { useEffect, useState } from "react";

export const Metrics = ({ ativo, metricValues }) => {
  const [cotacao, setCotacao] = useState(null);

  const calcularDiferencaPercentual = (valor1, valor2) => {
    return Math.abs((valor1 - valor2) / valor2) * 100;
  };

  const obterClasseCor = (valor) => {
    const diferencaPercentual = calcularDiferencaPercentual(valor, cotacao);

    if (diferencaPercentual <= 0.11) return "text-green-500";
    if (diferencaPercentual <= 0.16) return "text-yellow-500";
    return "";
  };

  useEffect(() => {
    setCotacao(null);
    const interval = "1s";
    const socketUrl = `wss://stream.binance.com:9443/ws/${ativo.toLowerCase()}@kline_${interval}`;

    const ws = new WebSocket(socketUrl);

    ws.onopen = () => {
      console.log("Conexão WebSocket estabelecida");
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const kline = message.k;
      setCotacao(kline.c);
    };

    ws.onerror = (error) => {
      console.error("Erro no WebSocket:", error);
    };

    return () => {
      ws.close();
    };
  }, [ativo]);

  const fPrice = cotacao ? Number(cotacao).toFixed(2) : "Loading...";

  const updatedMetricValues = metricValues
    .map((item) => {
      if (item.nome === "ativo") {
        // return { ...item, nome: ativo, valor: fPrice };
        return { ...item, nome: "Preço Atual", valor: fPrice };
      }
      if (
        item.nome !== "Afastamento -2% da MA20" &&
        item.nome !== "Distância +2% da MA20"
      ) {
        if (item.valor > fPrice) {
          return { ...item, nome: "Resistência" };
        }

        if (item.valor < fPrice) {
          return { ...item, nome: "Suporte" };
        }
      }
      return item;
    })
    .sort((a, b) => b.valor - a.valor);

  return (
    <div className="dark:modal-bg dark:text-zinc-200 pr-5 pl-5 md:pl-0 overflow-auto bg-transparent">
      <div className="hidden"></div>
      <section className="table-section">
        <table className="min-w-full table-auto border-collapse mt-4">
          <thead>
            <tr className="text-left ">
              <th className="px-4 py-2 border-b dark:border-white border-black">
                Métricas
              </th>
              <th className="px-4 py-2 border-b dark:border-white border-black">
                Preço
              </th>
            </tr>
          </thead>
          <tbody>
            {updatedMetricValues.map((item, index) => (
              <tr
                key={index}
                className={
                  item.nome === ativo
                    ? "dark:text-orange-500 text-blue-500"
                    : ""
                }
              >
                <td className="px-4 py-2 border-b dark:border-white border-black">
                  {item.nome}
                </td>
                <td
                  className={`px-4 py-2 border-b ${obterClasseCor(
                    item.valor
                  )} dark:border-white border-black`}
                >
                  {typeof item.valor === "number"
                    ? item.valor.toFixed(2)
                    : item.valor}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};
