import React, { useState, useEffect } from "react";
import { FaSun, FaMoon, FaUserCircle } from "react-icons/fa";
import { Modals } from "../Modal/Modal";

export const Header = ({ theme, setTheme }) => {
  useEffect(() => {
    document.body.classList.toggle("dark", theme === "dark");

    localStorage.setItem("theme", theme);
  }, [theme]);

  const handleThemeChange = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const [popupVisible, setPopupVisible] = useState(false); // Controle do popup
  const togglePopup = () => setPopupVisible(!popupVisible);

  return (
    <>
      <header className="h-8 modal-bg-less flex justify-between items-center p-7">
        <div>
          <img src="logo.png" alt="Logo" className="w-12 h-12" />
        </div>
        <div className="flex justify-center center gap-5">
          <div className="cursor-pointer" onClick={handleThemeChange}>
            {theme === "dark" ? (
              <FaMoon size={22} color="#ffffff" />
            ) : (
              <FaSun size={22} color="#ffffff" />
            )}
          </div>
          <div className="cursor-pointer" onClick={togglePopup}>
            <FaUserCircle size={22} color="#ffffff" />
          </div>
        </div>
      </header>
      <Modals togglePopup={togglePopup} popupVisible={popupVisible} />
    </>
  );
};
