export const SelectOptions = ({
  setDataInicio,
  dataInicio,
  setAtivo,
  ativo,
}) => {
  return (
    <section className="bg-white dark:modal-bg p-6 shadow-md">
      <h2 className="text-2xl font-semibold mb-4 dark:text-zinc-200">
        Seleção de Ativo e Data
      </h2>
      <div className="flex items-center space-x-6 w-full justify-between dark:text-zinc-200">
        <div className="flex flex-col w-full">
          <label htmlFor="ativo" className="mb-2 text-sm font-medium">
            Ativo:
          </label>
          <select
            id="ativo"
            value={ativo}
            onChange={(e) => setAtivo(e.target.value)}
            className="h-12 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:modal-bg-less dark:text-white dark:modal-bg-b-less"
          >
            <option value="BTCUSDT">BTCUSDT</option>
            <option value="ETHUSDT">ETHUSDT</option>
            <option value="SOLUSDT">SOLUSDT</option>
            <option value="DOGEUSDT">DOGEUSDT</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor="dataInicio" className="mb-2 text-sm font-medium">
            Data:
          </label>
          <input
            type="date"
            id="dataInicio"
            value={dataInicio}
            onChange={(e) => setDataInicio(e.target.value)}
            readOnly
            className="input-date h-12 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:modal-bg-less dark:text-white dark:modal-bg-b-less"
          />
        </div>
      </div>
    </section>
  );
};
