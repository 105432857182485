import React from "react";
import { PaymentFormGroup } from "../../components/Payments/PaymentFormGroup";

export const PaymentPage = () => {
  return (
    <>
      <PaymentFormGroup />
    </>
  );
};
