import React, { useState } from "react";
import {
  CardElement,
  useElements,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { makeRequest } from "../Axios/Axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IconChip } from "../icons/Icon";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#ffffff",
      "::placeholder": {
        color: "#b3b3b3",
      },
    },
    invalid: {
      color: "#ff6b6b",
    },
  },
};

const CheckoutForm = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name,
      },
    });

    if (error) {
      setError(error.message);
      setIsProcessing(false);
    } else {
      try {
        await makeRequest.post(
          "/payments/create-payment-method",
          { paymentMethod: paymentMethod },
          { withCredentials: true }
        );
        queryClient.invalidateQueries("payments");
      } catch (err) {
        setError("Erro ao processar o pagamento.");
      } finally {
        setIsProcessing(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-4">
        <input
          type="text"
          placeholder="Nome do titular do cartão"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full border border-gray-600 rounded-md p-3 text-white bg-transparent outline-none"
          required
        />
        <div className="w-full border border-gray-600 rounded-md p-3 text-black bg-transparent">
          <CardElement id="card-element" options={CARD_OPTIONS} />
        </div>
        {error && <div className="text-red-500 text-sm">{error}</div>}
        <button
          type="submit"
          disabled={isProcessing || !stripe}
          className="bg-blue-500 text-white py-2 px-2 rounded-lg hover:bg-blue-600 transition disabled:opacity-50 w-full"
        >
          {isProcessing ? "Adicionando..." : "Adicionar"}
        </button>
      </div>
    </form>
  );
};

export const CreditCardForm = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["payments"],
    queryFn: () =>
      makeRequest
        .get("/payments/get-payment-method", { withCredentials: true })
        .then((res) => res.data),
  });

  const sortedData = data?.sort((a, b) => {
    return b.status - a.status;
  });

  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
      <div className="scroll max-h-56 overflow-y-auto scrollbar-hide">
        {isLoading
          ? "Loading..."
          : error
          ? "Erro"
          : sortedData?.map((item, index) => {
              return (
                <div className="flex justify-center items-center mt-3">
                  <div className="w-full h-48 rounded-lg shadow-lg bg-gradient-to-b from-gray-300 to-gray-600 p-4">
                    <div className="flex justify-between">
                      <div className="-mt-2 -ml-2 mb-2">
                        <IconChip />
                      </div>
                      <div className="-mt-2  mb-2">
                        {item.status ? "ON" : "OFF"}
                      </div>
                    </div>

                    <div
                      className="text-white text-lg font-semibold mb-6 tracking-[0.2em]"
                      style={{ wordSpacing: "15px" }}
                    >
                      **** **** **** {item.last4}
                    </div>

                    <div className="flex justify-between items-center text-sm text-white">
                      <div className="text-left">
                        <p className="uppercase tracking-widest">
                          Nome do Cartão
                        </p>
                        <p className="font-semibold">{item.name}</p>
                      </div>
                      <div>
                        <p className="uppercase tracking-widest">Expiração</p>
                        <p className="font-semibold">{item.exp_year}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};

// export default CreditCardForm;

// const ProductDisplay = () => (
//   <section>
//     <div className="product">
//       <Logo />
//       <div className="description">
//         <h3>Starter plan</h3>
//         <h5>$20.00 / month</h5>
//       </div>
//     </div>
//     <form
//       action="http://localhost:8080/api/payments/create-checkout-session"
//       method="POST"
//     >
//       {/* Add a hidden field with the lookup_key of your Price */}
//       <input type="hidden" name="lookup_key" value="PLANO_MENSAL-522ad43" />
//       <button id="checkout-and-portal-button" type="submit">
//         Checkout
//       </button>
//     </form>
//   </section>
// );

// const SuccessDisplay = ({ sessionId }) => {
//   return (
//     <section>
//       <div className="product Box-root">
//         <Logo />
//         <div className="description Box-root">
//           <h3>Subscription to starter plan successful!</h3>
//         </div>
//       </div>
//       <form
//         action="http://localhost:8080/api/payments/create-portal-session"
//         method="POST"
//       >
//         <input
//           type="hidden"
//           id="session-id"
//           name="session_id"
//           value={sessionId}
//         />
//         <button id="checkout-and-portal-button" type="submit">
//           Manage your billing information
//         </button>
//       </form>
//     </section>
//   );
// };

// const Message = ({ message }) => (
//   <section>
//     <p>{message}</p>
//   </section>
// );

// export const Payments = () => {
//   let [message, setMessage] = useState("");
//   let [success, setSuccess] = useState(false);
//   let [sessionId, setSessionId] = useState("");

//   useEffect(() => {
//     // Check to see if this is a redirect back from Checkout
//     const query = new URLSearchParams(window.location.search);

//     if (query.get("success")) {
//       setSuccess(true);
//       setSessionId(query.get("session_id"));
//     }

//     if (query.get("canceled")) {
//       setSuccess(false);
//       setMessage(
//         "Order canceled -- continue to shop around and checkout when you're ready."
//       );
//     }
//   }, [sessionId]);

//   if (!success && message === "") {
//     return <ProductDisplay />;
//   } else if (success && sessionId !== "") {
//     return <SuccessDisplay sessionId={sessionId} />;
//   } else {
//     return <Message message={message} />;
//   }
// };

// const Logo = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     xmlnsXlink="http://www.w3.org/1999/xlink"
//     width="14px"
//     height="16px"
//     viewBox="0 0 14 16"
//     version="1.1"
//   >
//     <defs />
//     <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
//       <g
//         id="0-Default"
//         transform="translate(-121.000000, -40.000000)"
//         fill="#E184DF"
//       >
//         <path
//           d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
//           id="Pilcrow"
//         />
//       </g>
//     </g>
//   </svg>
// );
