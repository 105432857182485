import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Routers } from "./Routes/Routes";
import "./App.css";
import AOS from "aos"; // Importa o AOS
import "aos/dist/aos.css"; // Importa o CSS do AOS

const queryClient = new QueryClient();

function App() {
  // Inicializa o AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // A duração das animações (1 segundo)
      once: true, // As animações acontecerão apenas uma vez
    });
  }, []); // O array vazio garante que a inicialização aconteça apenas uma vez

  return (
    <QueryClientProvider client={queryClient}>
      <Routers />
    </QueryClientProvider>
  );
}

export default App;
