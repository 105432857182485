import axios from "axios";

let baseURL;

if (process.env.NODE_ENV === "production") {
  baseURL = process.env.REACT_APP_BASE_URL;
} else {
  baseURL = "http://localhost:8080/api";
}

export const makeRequest = axios.create({
  baseURL,
  withCredentials: true,
});
