import { Link } from "react-router-dom";

export const VejaConfirmeSenha = () => {
  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="pb-12 text-center">
            <h1 className="pb-1 animate-[gradient_6s_linear_infinite] bg-[linear-gradient(to_right,theme(colors.gray.200),theme(colors.indigo.200),theme(colors.gray.50),theme(colors.indigo.300),theme(colors.gray.200))] bg-[length:200%_auto] bg-clip-text font-nacelle text-3xl font-semibold text-transparent md:text-4xl">
              Enviamos um email pra você
            </h1>
            <h2 className="text-lg text-center p-5 text-white">
              Para modificar sua senha acesse o link que te enviamos
            </h2>
          </div>

          <div className="mt-6 text-center text-sm text-indigo-200/65">
            Já verificou a conta?{" "}
            <Link className="font-medium text-indigo-500" to="/entrar">
              Entre já
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
