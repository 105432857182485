import React, { useEffect, useState, Suspense } from "react";

const Chart = React.lazy(() => import("react-apexcharts"));

export const CandleChart = ({ options }) => {
  const [chartHeight, setChartHeight] = useState("100%");

  useEffect(() => {
    const handleResize = () => {
      setChartHeight(window.innerWidth < 768 ? "300%" : "100%");
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="w-full dark:modal-bg p-5">
      <Suspense fallback={<div>Loading...</div>}>
        {options.series.length !== 0 ? (
          <div></div>
        ) : (
          <div className="dark:text-white">Loading...</div>
        )}

        {options && options.series && Array.isArray(options.series) ? (
          <Chart
            options={options}
            series={options.series}
            type="candlestick"
            height={chartHeight}
          />
        ) : (
          <div>Dados do gráfico não disponíveis.</div>
        )}
      </Suspense>
    </div>
  );
};
